import React from 'react';
import {RadialGraphProps} from './RadialGraph.props';
import './RadialGraph.styles.css';
import {pieArcClasses,  PieChart } from '@mui/x-charts/PieChart';
import { styled } from '@mui/material/styles';
import {useDrawingArea} from "@mui/x-charts";
import {Tooltip} from "@mui/material";
import Box from "@mui/material/Box";

export const RadialGraph: React.FC<RadialGraphProps> = ({
    value,
    innerCircle = 16,
    outerCircle = 20,
    chartStartPoint = 0,
    chartLayoutPosition = {left: 10},
    isSkipAnimation = true,
    chartStyle = {},
    labelSize = 10,
    labelWeight = 700,
    labelFamily = 'Montserrat',
    labelColor = '#4D5858',
    isToolTipHover = false,
    prefixValue =''
}) => {
    let tooltipValue = ""
    let toolTipDescription = ""
  const colorRange = (value:number) => {
    if(value === 0){
        tooltipValue = "No threat"
        toolTipDescription = "No threat detected"
        return "#03A9F5"
    } else if(value === 50){
        tooltipValue = "Undetermined"
        toolTipDescription = "Unknown threat. Or email scanning failed due to multiple factors"
        return "#FCAB10"
    } else if(value >= 70){
        tooltipValue = "Threat detected"
        toolTipDescription = "High confidence in threat detected"
        return "#ED1C24"
    }
  }

    const chartEndPoint = chartStartPoint + (3.6 * value)
  const StyledText = styled('text')(({ theme }) => ({
    fill: labelColor,
    textAnchor: 'middle',
    dominantBaseline: 'central',
    fontSize: labelSize,
    fontWeight: labelWeight,
    fontFamily: labelFamily,
    cursor: isToolTipHover ? "pointer" : "default"
  }));

  const toolTipComponent = () => {
        return <Box sx={{
            fontFamily: 'Montserrat',
            fontSize: '13px',
            lineHeight: 'normal',
            color: '#FFF'
        }}>
            <span style={{display: 'flex', justifyContent: 'center', fontWeight: 700}}>{tooltipValue}</span>
            <span style={{fontWeight: 500}}>{toolTipDescription}</span>
        </Box>
    }

  const PieCenterLabel = ({ children }: { children: React.ReactNode }) => {
    const { width, height, left, top } = useDrawingArea();
    const textLabel = <StyledText x={left + width / 2} y={top + height / 2}>
        {children}
    </StyledText>
  return isToolTipHover ? <Tooltip placement={"top"} title={toolTipComponent()} componentsProps={{tooltip: {sx:{maxWidth: "450px"}} }}>
          {textLabel}
      </Tooltip> : textLabel
  }

  const data = [
    {
      startAngle: chartStartPoint,
      endAngle: 360,
      paddingAngle: 0,
      innerRadius: innerCircle,
      outerRadius: outerCircle,
      data: [{value: 100, color: '#f2f3f3'}]
    },
    {
      startAngle: chartStartPoint,
      endAngle: chartEndPoint,
      innerRadius: innerCircle,
      outerRadius: outerCircle,
      data: [{value: value, color: colorRange(value)}],
    },
  ]

  return (
      <PieChart
          series={data}
          sx={{[`& .${pieArcClasses.root}`]: chartStyle }}
          skipAnimation={isSkipAnimation}
          tooltip={{ trigger: 'none' }}
          slotProps={{legend: { hidden: true } }}
          margin={chartLayoutPosition}
      >
        <PieCenterLabel>{value}{prefixValue}</PieCenterLabel>
      </PieChart>
  );
};
