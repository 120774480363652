import React, {useState, useRef} from 'react';
import {MailHeaderViewerProps} from "./MailHeaderViewer.props";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import moment from "moment";
import "./MailHeaderViewer.styles.css"
import {LabelIcon} from "../../atoms/LabelIcon";
import {Modal} from "../../atoms/Modal";
import {Images} from "../../../configs/images";
import {SearchFilter} from "../SearchFilter";
import Highlighter from "react-highlight-words";
import {HeaderDeliveryInformationModal} from "../../organisms/HeaderDeliveryInformationModal";
import {checkStatus} from "../../../utils/common";

export const MailHeaderViewer: React.FC<MailHeaderViewerProps> = ({
    inspectionData,
    content
}) => {
    const [openHeaderViewDetail, setOpenHeaderViewDetail] = useState(false)
    const [headerDetailIndex, setHeaderDetailIndex] = useState(0)
    const [searchKeyWord, setSearchKeyWord] = useState("")
    const  headerContentRef= useRef(null);
    let tz_name = 'Etc/UTC';
    const localStorageUserData = localStorage.getItem('userData');

    if(localStorageUserData) {
        const parseUserData = JSON.parse(localStorageUserData)
        tz_name = parseUserData["tz_name"]
    }

    const nameStandardized = {
        dmarc:{
            text: "DMARC",
            tabDetailIndex: 0
        },
        spf: {
            text: "SPF",
            tabDetailIndex: 1
        },
        dkim: {
            text: "DKIM",
            tabDetailIndex: 2
        },
        arc: {
          text: "ARC",
          tabDetailIndex: 3
        }
    }

  const headersList = content;
  const toDateTime = (header: any) => {
    try {
      let headersItem = header.split(';');
      let lastItem = headersItem[headersItem.length - 1];
      let converted = moment(lastItem).utc()
      if(converted.isValid()) {
        headersItem[headersItem.length - 1] = converted.tz(tz_name).format('DD/MM/YYYY hh:mm A')
      }
      return headersItem.join('; ')
    } catch (e) {
      return header
    }
  }

  const handleOpenDetailPopup = (index:number) => {
      setHeaderDetailIndex(index)
      setOpenHeaderViewDetail(true)
  }

    const handleSanitizeForSearch = (value: string) => {
        value = value.replace(/[\r\n]+/g,"")
        return value.replace(/\s\s+/g, ' ')
    }
  const capitalizeFirstLetter=(str: string) => {
    if (!str) return '';
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  const listCard=['dmarc','spf','dkim','arc']
  return (
      <Box className={'header-viewer-wrapper'}>
          {
              Object.keys(inspectionData).length > 0 && <Box>
                  <span className={'title'}> Email Authentication Results </span>
              <Box  sx={{display:'flex', flexWrap:'wrap', gap:'24px', margin:'24px 0 40px 0'}}>
                {listCard.map((key:string, index:number)=>{
                  return(
                    <Box key={key+index} className={`delivery-information-wrapper ${checkStatus(inspectionData[key]['result'])}`} sx={{width:'351px', padding:'16px', position:'relative', minHeight:'124px'}}>
                      <Box sx={{display: "flex", alignItems: "center"}}>
                        <span className={'sub-title'}> {key.toUpperCase()}: </span>
                        <LabelIcon text={capitalizeFirstLetter(inspectionData[key]['result'])} icon={inspectionData[key]['result'] === 'pass' ? "success" : "error"}/>
                      </Box>
                      <p style={{padding: '0 0 24px 0', fontWeight: 400, fontSize:'14px'}}>{inspectionData[key]['hint']}</p>
                      <p data-testid="view-detail" style={{marginTop: '10px', fontWeight: 600, color: "#345C8B", cursor: "pointer", display:'inline-flex', position:"absolute", bottom:0,marginBottom: '16px'}}
                         onClick={() => handleOpenDetailPopup(nameStandardized[key]['tabDetailIndex'])}>View Detail</p>
                    </Box>
                  )
                  })
                }
              </Box>
            </Box>
          }
          <span className={'title'}> Header Content </span>
          <Stack spacing={2} className={'email-header-viewer-content'} ref={headerContentRef}>
              <SearchFilter
                  titleInput={'Search by keywords'}
                  iconSrc={Images.SearchIcon}
                  borderRadius={'4px'}
                  setInputValue={setSearchKeyWord}
              />
              {content.length === 0 ? <p className={"nodata-content"}> No header were contained in this
                  email</p> : headersList.map((item: any, index: any) => {
                  return (<Box key={index}>
                      <Typography className={"email-header-content-attribute"} sx={{fontWeight: '700'}} variant={'body2'} display="inline">
                          <Highlighter
                              highlightClassName="header-content-highlighted"
                              searchWords={[searchKeyWord]}
                              autoEscape={true}
                              highlightStyle={{backgroundColor: "#FFD54F"}}
                              textToHighlight={handleSanitizeForSearch(`${item.key}:`)}
                          />
                      </Typography>
                      <Typography className={"email-header-content-attribute"} sx={{wordWrap: 'break-word', wordBreak: 'break-all'}} variant={'body2'} display="inline">
                          <Highlighter
                              highlightClassName="header-content-highlighted"
                              searchWords={[searchKeyWord]}
                              autoEscape={true}
                              highlightStyle={{backgroundColor: "#FFD54F"}}
                              textToHighlight={handleSanitizeForSearch(` ${toDateTime(item.value)}`)}
                          />
                      </Typography>
                  </Box>)
              })}
          </Stack>

          <Modal idModal='modal-header-view-detail'
                 openDialog={openHeaderViewDetail}
                 variant={'header_view_detail'}
                 title={'Email Authentication Results'}
                 children={<HeaderDeliveryInformationModal payLoad={inspectionData} tabIndex={headerDetailIndex}/>}
                 onClose={() => setOpenHeaderViewDetail(false)}
          />
      </Box>
  );
};
