import React from 'react';
import {EmailAuthenticationResultsProps} from './EmailAuthenticationResults.props';
import './EmailAuthenticationResults.styles.css'
import WarningIcon from "@mui/icons-material/Warning";
import Skeleton from "@mui/material/Skeleton";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import {checkStatus} from "../../../utils/common";

export const EmailAuthenticationResults: React.FC<EmailAuthenticationResultsProps> = ({
    title,
    payload={},
  }) => {
  // const payload1: any = title === 'DMARC' ? dmarc : spf;
  const highlightTitleContent = (params: string[]) => {
    const formattedData = params?.map((item: string, index) => {
      const [beforeColon, afterColon] = item.split(/:(.+)/); // Tách chuỗi tại dấu `:`
      return (<>
          <div key={index}>
            <p style={{fontWeight: 700, fontSize: 14, margin: 0, lineHeight: '22.4px'}}>{beforeColon}:</p>
            {afterColon}
          </div>
          <br/>
        </>
      );
    });

    return <div>{formattedData}</div>;
  }

  const cardStatus = (data: any) => {
    const {result, hint} = data;
    return (
      <div className={`record-container ${checkStatus(result)}`}>
        <div className={`desc-content ${checkStatus(result)}`}>
          <div className={`record-status ${checkStatus(result)}`}>
            {checkStatus(result) === 'pass' ? <CheckCircleIcon/> : <CancelIcon/>}
            <span>{result}</span><span style={{fontWeight: 700, fontSize: 16, color: 'black'}}> - {hint}</span>
          </div>
          <div className={'record-desc'}>The email's sender is unverified. It may be a scam and should not be opened or
            responded to.
          </div>
        </div>
      </div>
    )
  }

  return (
    <>
      <div className={'email-authentication-results-container'}>
        {cardStatus(payload)}
        {title === 'SPF' ? <>
            <p style={{margin: '32px 0 8px', fontWeight: 700, fontSize: 14}}>Email from and sending server:</p>
            <div className={'email-authentication-results-container'} style={{margin: 0, padding: '16px'}}>
              Email from <span className={'verifier-value'}>{payload?.domain}</span> originates from the sending
              server <span className={'verifier-value'}>{payload?.ip}</span>
            </div>
          </> :
          <p style={{margin: 0, fontWeight: 700, fontSize: 14}}>Domain: <span
            className={'verifier-value'}>{payload?.domain}</span></p>
        }
        <p style={{margin: '32px 0 8px', fontWeight: 700, fontSize: 14}}>Related Email Headers:</p>
        <div className={'email-authentication-results-container'} style={{margin: 0, padding: '16px'}}>
          {highlightTitleContent(payload?.headers_used)}
        </div>
        {title === 'SPF' && <>
            <p style={{margin: '32px 0 8px', fontWeight: 700, fontSize: 14}}>Related DNS records:</p>
            <div className={'email-authentication-results-container'} style={{margin: 0, padding: '16px'}}>
              {'example.com, TXT: v=spf1 ip4:.... '}
            </div>
        </>}
      </div>
    </>
  );
};
