import React, {useEffect, useState} from 'react';
import {Chip} from "@mui/material";
import {MailViewer} from "../../molecules/MailViewer";
import {TabGroup} from "../../molecules/TabGroup";
import {MailHeaderViewer} from "../../molecules/MailHeaderViewer";
import {downloadActivityLog, getActivityLog, getInboxMessageEmail} from '../../../api/emailTriage';
import {ScanResultViewer} from "../../molecules/ScanResultViewer";
import {isMobileOnly} from "react-device-detect";
import { ActivityLog } from '../../molecules/ActivityLog';
import {LoadingPage} from "../LoadingPage";
import {Pagination} from "../../atoms/Pagination";
import {AnalysisSummaryViewer} from "../../molecules/AnalysisSummaryViewer";
import {Error} from "@mui/icons-material";
import {handleSpinEmailDetailTabs} from '../../../utils/common';

export const EmailDetails: React.FC<any> = (props) => {
  const [emailBody, setEmailBody] = useState<any|undefined>()
  const [emailHeaders, setEmailHeaders] = useState([])
  const [headerInspectionData, setHeaderInspectionData] = useState({})
  const [scanResult, setScanResult] = useState(undefined)
  const [srcImage, setSrcImage] = useState({})
  const [activityLog, setActivityLog] = useState([]);
  const [isShowLoading, setIsShowLoading] = useState(false);
  const [sortCol, setSortCol] = useState({})
  const [downloadActivityLogsLink, setDownloadActivityLogsLink] = useState('')
  const [loadingPage, setLoadingPage] = useState(false)

  const [currentPerPageActivityLogs, setCurrentPerPageActivityLogs] = useState(10);
  const [currentPageActivityLogs, setCurrentPageActivityLogs] = useState(1);
  const [startPointActivityLogs, setStartPointActivityLogs] = useState(0);
  const [endPointActivityLogs, setEndPointActivityLogs] = useState(0);
  const [maxPageActivityLogs, setMaxPageActivityLogs] = useState(0);
  const [totalRecordsActivityLogs, setTotalRecordsActivityLogs] = useState(0)
  const [isShowActiveTabErrorIcon, setIsShowActiveTabErrorIcon] = useState({analysisSummary: false, scanResultTab: false, emailContentTab: false, headerTab: false})

  const handleGetImage = (src:any) => {
    if(src) {
      for(let i = 0; i < src.length; i++) {
        if(src[i].is_image) {
          const base64Img = src[i].payload
          setSrcImage((prev:any) => {
            return ({
              ...prev,
              [i]: base64Img
            })
          })
        }
      }
    }
  }

  useEffect(() => {
    setIsShowLoading(true);
    getInboxMessageEmail(props.emailID).then((result: any) => {
      setEmailBody(result.data.body_html || /* istanbul ignore next */result.data.body_plain)
      setEmailHeaders(result.data.header)
      // setHeaderInspectionData(result.data.header_inspection)
      setHeaderInspectionData(result?.data?.header_result || {})
      setScanResult(result.data.scan_results || /* istanbul ignore next */{})
      handleGetImage(result.data.attachments)
      props.setOriginalEmail(result.data.original_email)
      props.setScanResult(result.data.scan_results || {})
      props.onGetSuccess(result.data)
    }).catch((error) => {
      setEmailBody('');
    });
    setIsShowLoading(false);
  }, [])

  const loadActivityLogs = (params:any) => {
    setIsShowLoading(true);
    getActivityLog(params).then((res) => {
      setActivityLog(res.data.list.map((entry: any, index: number) => {
        const { ca_timestamp, message, name, created_by, ip_address } = entry;
        return {
          email_id: index.toString(),
          date_modified: new Date (parseInt(ca_timestamp) * 1000),
          action_details: message,
          done_by: {
            name,
            created_by,
          },
          ip_address,
        };
      }));
      setStartPointActivityLogs(res.data.startItemsPerPage)
      setEndPointActivityLogs(res.data.endItemsPerPage)
      setMaxPageActivityLogs(res.data.maxPage)
      setTotalRecordsActivityLogs(res.data.totalItems)
      setIsShowLoading(false);
    });
  }

  useEffect(() => {
    const emailId = props.emailID;
    let params:any = {
      sortField: sortCol[0]?.sort || 'desc'
    }
    params["emailId"] = emailId
    params["currentPage"] = currentPageActivityLogs
    params["currentPerPage"] = currentPerPageActivityLogs
    loadActivityLogs(params)
  }, [sortCol, currentPageActivityLogs, currentPerPageActivityLogs, props.triggerLoadLog])

  useEffect(() => {
    const isAnalysisIconShowed = props.threatAnalyzedOverview && props.threatAnalyzedOverview.score >= 40
    const isHeaderIconShowed = Object.keys(headerInspectionData).length > 0 && (
        // headerInspectionData["dmarc_compliant"] !== true ||
        // headerInspectionData["dkim_alignment"] !== true ||
        // headerInspectionData["dkim_authenticated"] !== true ||
        // headerInspectionData["spf_alignment"] !== true ||
        // headerInspectionData["spf_authenticated"] !== true
      headerInspectionData["spf"]['result'] !== 'pass' ||
      headerInspectionData["dkim"]['result'] !== 'pass' ||
      headerInspectionData["dmarc"]['result'] !== 'pass' ||
      headerInspectionData["arc"]['result'] !== 'pass'
    )

    setIsShowActiveTabErrorIcon((prevState) => {
      return {
        ...prevState,
        analysisSummary: isAnalysisIconShowed,
        scanResultTab: isAnalysisIconShowed,
        headerTab: isHeaderIconShowed
      }
    })
  }, [props.threatAnalyzedOverview.score, headerInspectionData]);
  const handleHeaderTabIcon = () => (<Error sx={{fontSize: '16px', color: '#B20007', marginLeft: '10px'}}/>)

  const headerTableTitles = [
    <div>
      ANALYSIS SUMMARY
      {isShowActiveTabErrorIcon.analysisSummary ? handleHeaderTabIcon() : <></>}
    </div>,
    <div>
      SCAN RESULTS
      {isShowActiveTabErrorIcon.scanResultTab ? handleHeaderTabIcon() : <></>}
    </div>,
    <div>
      EMAIL CONTENT
      {isShowActiveTabErrorIcon.emailContentTab ? handleHeaderTabIcon() : <></>}
    </div>,
    <div>
      HEADER
      {isShowActiveTabErrorIcon.headerTab  ? handleHeaderTabIcon() : <></>}
    </div>,
    <div>ACTIVITY LOGS {totalRecordsActivityLogs >= 0 ?
      (isMobileOnly ? "(" + totalRecordsActivityLogs + ")" : <Chip sx={{backgroundColor: '#808888', color: 'white'}} size={'small'} label={totalRecordsActivityLogs}/>) : null}</div>,
  ]

  const onSort = (model:any) => {
    console.log(model)
    setSortCol(model)
  }

  const downloadHandler = (emailId: string) => {
    setLoadingPage(true)
    downloadActivityLog(emailId).then((response:any) => {
      if (response["data"]["presigned_url"] !== ''){
        setDownloadActivityLogsLink(response["data"]["presigned_url"])
      }
      setLoadingPage(false)
    }).catch((error:any) => {
      setLoadingPage(false)
      console.log("download error", error)
    });
  };

  const handlePerRowsChange = (e: any) => {
    const value = parseInt(e.target.value)
    setCurrentPageActivityLogs(1)
    setCurrentPerPageActivityLogs(value)
  }

  const handlePageChange = (page: number) => {
    setCurrentPageActivityLogs(page)
  }

  const paginationComponent = () => {
    return <Pagination
      itemsPerPage = {currentPerPageActivityLogs}
      currentPage = {currentPageActivityLogs}
      startPoint = {startPointActivityLogs}
      endPoint = {endPointActivityLogs}
      maxPage = {maxPageActivityLogs}
      totalItems = {totalRecordsActivityLogs}
      handlePerRowsChange={handlePerRowsChange}
      handlePageChange={handlePageChange}
    />
  }

  const handleScrollHeaderTab = () => {
    let pinTabHeaderStyle = {
      'zIndex': '100',
      'position': 'fixed',
      'top': '58px',
      'backgroundColor': '#fff',
      'borderRadius': '4px',
      'boxShadow': '0 0 10px 0 rgba(0, 0, 0, 0.25)',
    }
    handleSpinEmailDetailTabs(pinTabHeaderStyle)
  }

  useEffect(() => {
    // 0 is the index of analysis summary tab
    window.addEventListener('scroll', handleScrollHeaderTab)
    return () => {
      window.removeEventListener('scroll', handleScrollHeaderTab);
    }
  }, [])

  const analysisScore = Object.keys(props.threatAnalyzedOverview).length > 0 ? props.threatAnalyzedOverview.score : undefined
  const tabContents = [
    <AnalysisSummaryViewer payLoad={props.threatAnalyzedOverview} handleViewScanResultDetail={props.setInboxDetailActiveTab} setLoadingPage={setLoadingPage}/>,
    <ScanResultViewer data={props.threatAnalyzedDetails} setLoading={setLoadingPage} analysisScore={analysisScore} />,
    <MailViewer content={emailBody} image={srcImage}/>,
    <MailHeaderViewer content={emailHeaders} inspectionData={headerInspectionData} />,
    <ActivityLog
      content={activityLog}
      isShowLoading={isShowLoading}
      downloadHandler={downloadHandler}
      onSort={onSort}
      emailId={props.emailID}
      downloadActivityLogsLink={downloadActivityLogsLink}
      paginationComponent={paginationComponent}
      setDownloadActivityLogsLink={setDownloadActivityLogsLink}
    />,
  ]

  return (
    <div id={'email-detail'}>
      <TabGroup setValue={props.setInboxDetailActiveTab} tabValue={props.inboxDetailActiveTab} tabTitles={headerTableTitles} tabContents={tabContents} setActiveFilter={(e:any) => {console.log(e)}}/>
      <LoadingPage open={loadingPage}/>
    </div>
  );
};
