import {Images} from "../configs/images";

export const ADMIN_ROLE = 'administrator'
export const COMPANY_ADMIN_ROLE = 'company_admin'

export const CONSTANTS = {
  SERVICE_TYPES: {
    INBOX: "inboxes",
    EMAIL: 'email',
    DASHBOARD: 'dashboard',
    REFERENCE_DATA: 'referenceData',
    SUBSCRIPTIONS: 'subscriptions'
  },
  'ROUTER': [
    {
      name: 'Dashboard',
      href: '/dashboard',
      linkIcon: Images.DashboardIcon
    },
    {
      name: 'Inbox',
      href: '/inbox',
      // linkIcon: Images.MailIcon,
      child: [
        {
          name: 'Archived',
          href: '/archived',
          // linkIcon: Images.MailIcon,
        }
      ]
    },
    {
      name: 'Automation Rules',
      href: '/automation-rules',
      linkIcon: Images.BuildIcon
    },
    // {
    //   name: 'Reports',
    //   href: '/reports',
    //   linkIcon: Images.ReportIcon
    // },
    {
      name: 'Settings',
      href: '/settings',
      // linkIcon: Images.SettingIcon
    }
  ],
  'SETTING': [
    {
      name: 'Dashboard',
      href: '/dashboard',
      linkIcon: Images.DashboardIcon
    },
    {
      name: 'Inbox',
      href: '/inbox',
      // linkIcon: Images.MailIcon,
      child: [
        {
          name: 'Archived',
          href: '/archived',
          // linkIcon: Images.MailIcon,
        }
      ]
    },
    {
      name: 'Settings',
      href: '/settings',
      linkIcon: Images.SettingIcon
    }
  ],
  "PRIORITIES": {
    "High": {
      'linkIcon': '',
      'color': '#ED1C24',
      'background': '#ED1C24',
      "hoverBg": "#B20007",
      "label": 'High',
      "id": 'High',
      "borderRadius": 100
    },
    "Medium": {
      'linkIcon': '',
      'color':'#FCAB10',
      'background':'#FCAB10',
      "hoverBg": "#D88E00",
      "label": 'Medium',
      "id": 'Medium',
      "borderRadius": 100
    },
    "Low": {
      linkIcon: '',
      background: '#03A9F5',
      "hoverBg": "#007AB2",
      color: '#03A9F5',
      "label": 'Low',
      "id": 'Low',
      "borderRadius": 100
    },
    "Unknown": {
      "linkIcon": '',
      "background": '#909090',
      "hoverBg": "#707070",
      "color": '#fff',
      "label": 'Unknown',
      "id": 'Unknown',
      "borderRadius": 100
    }
  },
  "CATEGORIES": {
    "Threat": "#ED1C24",
    "Clean": "#03A9F5",
    "Unknown": "#808888"
  },
  "STATUS":{
    "Received": {
      "bgColor": "",
      "hoverColor": "",
      "color": "",
      "label": 'Received',
      "id": 'Received',
      'background': '#EDEDED',
      "hoverBg": "#EDEDED",
    },
    "InReview": {
      "bgColor": "43BD77",
      "hoverColor": "",
      "color": "",
      "selectColor": "",
      "label": 'In Review',
      "id": "In Review",
      'background': '#E1EFEF',
      "hoverBg": '#E1EFEF',
    },
    "Resolved": {
      "bgColor": "",
      "hoverColor": "",
      "color": "",
      "label": 'Resolved',
      "id": 'Resolved',
      "background": '#f2fad9',
      "hoverBg": "#f2fad9",
    },
  },
  "DASHBOARD": {
    "MINI_CHART": {},
    "DONUT_CHART": {},
  }
};
export const PRIORITY = "Priority";
export const CATEGORY = "Category";
export const SCAN_RESULT = "Scan Result";
export const STATUS = "Status";
export const SEND_EMAIL = "Send Email";
export const PURGE = "Purge";
export const PURGE_OPTION = "Phish Clear";
export const PURGE_TITLE = "Purge selected emails within your organisation";
export const DELETE = "Delete";
export const EDIT = "Edit";
export const ARCHIVE = "Archive";
export const MESSAGES_STATUS_CHANGED = "Selected messages’ status will be changed to";
export const MESSAGES_PRIORITY_CHANGED = "Selected messages’ priority will be changed to";
export const MULTIPLE_SELECTED_EMAIL = "You can’t send email when choosing multiple messages.";
export const MULTIPLE_SELECTED_PHISH_CLEAR = "You can’t phish clear when choosing multiple messages.";
export const MESSAGES_INFECTED_DETAIL = "Phish Focus has identified a potential threat. Please exercise extreme caution when working with this email.";
export const MESSAGES_UNDETERMINED_DETAIL = "Phish Focus was unable to determine if a threat exists in this email. Please exercise caution when working with this email.";
export const MESSAGES_NO_THREAT_DETAIL = "Phish Focus did not detect any threat in this email. You should remain cautious when working with this email.";
export const MESSAGES_ANALYSING_DETAIL = "Phish Focus is still analysing this email to identify any threats. Please check again shortly.";
export const MESSAGES_INFECTED_SENDMAIL = "Threat it yet to be determined, come back at a later time when the email has been analysed.";
export const AUTOMATION_RULES = "Automation Rules";
export const AUTOMATION_RULES_INFO = "These automation rules will apply to emails based on their scan results. This can allow the system to automatically action emails in the workflow and respond to users with immediate feedback.";
export const BLOCK_LIST = "Block List";
export const BLOCK_LIST_INFO = "Block List";
export const BLOCK_LIST_ACTIVITY_LOGS = "Activity Logs";
export const CONVERT_TO_EMAIL_TEMPLATE = "Convert with Phlipper";
export const ADD_TO_BLOCK_LIST = "Add to Block List";
// Purge function
export const PURGE_NOTICE = "The original emails of the selected messages will be permanently remove from all users’ inboxes of the current zone.";
export const PURGE_CONFIRM = "Are you sure you want to remove them?";
export const LOGIN_AZURE_TITLE = "Log into Microsoft 365 account";
export const LOGIN_AZURE_CONTENT = "To take this action, please log in to the zone’s admin Azure account.";
export const EXPIRED_SESSION_LOGIN_AZURE = "Your login session to Azure has expired. Please login again to take this action.";
export const LOGGED_AZURE_TITLE = "Logged into Microsoft 365 account successfully";

export const SEND_EMAIL_MODAL_TOOLTIP = 'This address is not a mail box. The recipient can’t reply to this email address.';

export const conditionValues = [
    'Infected',
    'Unscannable',
    'Error',
    'Clean',
];

export const status = [
    'Received',
    'In Review',
    'Resolved',
];

export const priorities = [
    'High',
    'Medium',
    'Low',
];

export const categoríes = [
    'Threat',
    'Clean',
    'Unknown',
];

export  const BASE_DOMAIN = {
  TEST_DOMAIN: 'Test_Domain',
  SAVE_ADD_DOMAIN: 'Save_Add_Domain',
  SAVE_DOMAIN: 'Save_Domain',

}

export  const BASE_DOMAIN_MODES={
  ADD: 'Add_New',
  EDIT: 'Edit',
}

export  const BASE_DOMAIN_STATUS={
  SUCCESS: 'Success',
  FAIL: 'Fail',
  NONE: '',
}

export const BLOCK_LIST_SYNC_STATUS= {
  ACTIVE: 'active',
  FAILED: 'failed',
  PENDING: 'pending',
  EXPIRED: 'expired'
}

export const BLOCK_LIST_ATTRIBUTE_TYPE= {
  URL: 'url',
  SENDER: 'sender',
  FILE_HASH: 'file_hash'
}

export const BLOCK_LIST_ACTIVITY_LOGS_ACTION_TYPE= {
  DELETED: 'deleted',
  CREATED: 'created',
  EXPIRED: 'expired',
  RESET_EXPIRED: 'reset_expired'
}

export const STATUS_CODE = {
  OK: 200
}

export const BLOCK_LIST_DURATION = {
  0: 'Never expire (Defaults)',
  7: '7 days',
  30: '30 days'
}

export const MENU_CLASSES = {
  "AddToBlockList": 'menu-group-1',
  "Purge": 'menu-group-1',
  "Sepline1": "menu-sep-line",
  "Sepline2": "menu-sep-line"
}

export const BLOCK_LIST_API_ACTIONS = {
  CREATE_BLOCK_LIST:'CREATE_BLOCK_LIST',
  VALIDATE_BLOCK_LIST:'VALIDATE_BLOCK_LIST',
  REMOVE_BLOCK_LIST_ENTRY: 'REMOVE_BLOCK_LIST_ENTRY',
  UPDATE_BLOCK_LIST_ENTRY: 'UPDATE_BLOCK_LIST_ENTRY',
  DOWNLOAD_BLOCK_LIST_ACT_LOGS: 'DOWNLOAD_BLOCK_LIST_ACT_LOGS',
  GET_BLOCK_LIST_ACT_LOGS: 'GET_BLOCK_LIST_ACT_LOGS'
}

export const SETTING_TYPE_AUTOMATION_RULE = 'automation_rule';
export const SETTING_TYPE_REPORTED_EMAILS_ACTIONS = 'reported_emails_actions';
export const SETTING_BLOCK_LIST = 'block_list';
export const ROUTE_LOGOUT_PAGE = '/logout';


/* Error message */
export const EXPORT_ERROR_MESSAGES = {
  ERROR_UNKNOWN: "There was an issue when converting the email.<br/> Please try again later.",
  ERROR_INVALID_CHARS: "The email contains special characters which prevent it from being converted into an email template."
}

export const HEADER_PARSING_STATUS = {
  PASS: 'pass',
  ERROR: 'error',
  FAIL:'fail',
  SOFTFAIL:'softfail',
  NEUTRAL:'neutral',
  NONE:'none',
  MISSING:'missing',
  NOMATCH:'noMatch'
}