import React, { useState} from 'react'
import './HeaderDeliveryInformationModal.styles.css';
import {HeaderDeliveryInformationModalProps} from './index';
import {Box, } from "@mui/material";
import {TabGroup} from "../../molecules/TabGroup";
import {EmailAuthenticationResults} from "../../molecules/EmailAuthenticationResults";

export const HeaderDeliveryInformationModal: React.FC<HeaderDeliveryInformationModalProps> = ({
    payLoad,
    tabIndex = 0
}) => {
    const [tabValue, setValue] = useState(tabIndex);

    const tabContents = [
        <EmailAuthenticationResults title={'DMARC'} payload={payLoad?.dmarc}/>,
        <EmailAuthenticationResults title={'SPF'} payload={payLoad?.spf}/>,
        <EmailAuthenticationResults title={'DKIM'} payload={payLoad?.dkim}/>,
        <EmailAuthenticationResults title={'ARC'} payload={payLoad?.arc}/>
    ]

    return (
        <Box className={'delivery-information-modal-container'}>
            <TabGroup setValue={setValue}
                      tabValue={tabValue}
                      tabTitles={[
                            <div>DMARC</div>,
                            <div>SPF</div>,
                            <div>DKIM &nbsp;
                                {/*{*/}
                                {/*    payload["dkim"].length > 0 && payload["dkim"][0]?.dkim_details.length > 1 ?*/}
                                {/*        <Chip sx={{backgroundColor: '#808888', color: 'white'}} size={'small'} label={payload["dkim"][0]?.dkim_details.length}/>*/}
                                {/*    : null*/}
                                {/*}*/}
                            </div>,
                            <div>ARC</div>,
                      ]}
                      tabContents={tabContents}
                      statistic={false}>
            </TabGroup>
        </Box>
    )
}
